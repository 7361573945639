<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Breadcrumbs">
                    <template v-slot:description>
                        <div>
                            <div>import { HbBreadcrumb } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Breadcrumb Example + Code" class="mt-4 mb-6">
            <div class="ma-4 hb-cloud-lighter">
                <hb-breadcrumb @click="handleEmittedClickEvent('@click')">
                    Back to Wherever You Were
                </hb-breadcrumb>
                <hb-header>
                    <template v-slot:left>
                        <hb-page-header
                            title="Access Control"
                            description="Edit your access control integration here."
                        >
                        </hb-page-header>
                    </template>
                    <template v-slot:right>
                        <hb-text-field
                            box
                            placeholder="Search"
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            width="370px"
                        ></hb-text-field>
                        <div class="ml-2">
                            <HbBtn class="ml-1" icon tooltip="Bulk Actions" mdi-code="mdi-format-line-spacing" />
                            <HbBtn class="ml-1" icon tooltip="Save Report" mdi-code="mdi-content-save" />
                            <HbBtn class="ml-1" icon tooltip="Export Report" mdi-code="mdi-download" />
                            <HbBtn class="ml-1" icon tooltip="Set Columns" mdi-code="mdi-table-actions-custom-2" />
                            <HbBtn class="ml-1" icon tooltip="Advanced" mdi-code="mdi-table-actions-custom-3" />
                            <HbBtn class="ml-1" icon tooltip="Filter" mdi-code="mdi-filter-variant" />
                        </div>
                    </template>
                </hb-header>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-breadcrumb @click="handleEmittedClickEvent('@click')">
    Back to Wherever You Were
&lt;/hb-breadcrumb>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemBreadcrumbs",
        data: function() {
            return {
                success: false,
                description: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'id', type: 'string', default: 'undefined', description: 'Sets the id for the clickable element. Please always set an id for all breadcrumbs as we need this for automated testing.' },
                    { name: 'to', type: 'string', default: 'undefined', description: 'Sets the vue route to go to when the breadcrumb is clicked.' },
                    { name: 'href', type: 'string', default: 'undefined', description: 'Sets the href value for when the breadcrumb is clicked. Not common to use this for breadcrumbs but the option is there.' },
                    { name: 'target', type: 'string', default: 'undefined', description: 'Sets the "target" attribute value when "href" is also set. Not common to use this for breadcrumbs but the option is there. For example, set "target=_blank" to open in a new tab when clicked.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when the breadcrumb is clicked. Setting the "to" prop will disable this event.' }
                ],
            };
        },
        methods: {
            handleEmittedClickEvent(e){
                this.success = true;
                this.description = "This " + e + " action is emitted from it's base level custom component.";
            }
        }
    }
</script>

<style scoped>

</style>